import * as React from "react";
import { Link } from "react-router-dom";

export default () => (
  <div
    style={{
      background:
        "linear-gradient(180deg,rgb(4.12, 164.69, 136.01) 6.5%,rgb(29.8, 198.69, 168.82) 64%)",
      paddingTop: 48,
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    }}
  >
    <p className="bh text-center">ב״ה</p>
    <div className="fs-20 fw-900 text-center uppercase-text">
      <p>Chabad on Campus</p>
    </div>
    <div className="header-logo">
      <img alt="bckg" src="/images/chabad-logo.svg" />
    </div>
    <p
      className="fs-100 fw-800 mb-16 light-text text-center uppercase-text"
      style={{ textShadow: "0 0 4px rgba(0, 0, 0, 0.25)" }}
    >
      Sorry!
    </p>
    <p
      className="fs-50 fw-600 light-text text-center mb-32"
      style={{ textShadow: "0 0 4px rgba(0, 0, 0, 0.25)" }}
    >
      The page you are looking for cannot be found.
    </p>
    <div className="flex flex-justify-center">
      <Link
        to="/"
        className="fs-18 fw-500 light-text text-center mb-120"
        style={{ textShadow: "0 0 4px rgba(0, 0, 0, 0.25)" }}
      >
        Take Me Home
      </Link>
    </div>
  </div>
);
